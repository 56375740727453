import React from 'react'
import PropTypes from 'prop-types'
import Login from '../login'
import LanguageSwitcher from '../language-switcher'
import styled from 'styled-components'
import HeaderMenu from './header-menu'
import useScrollDirection from '../../hooks/use-scroll-direction'
import { DEFAULT_COLOR } from '../../helpers/colors'
import ContactButton from '../contact-button'
import { breakpoints } from '../../helpers/media'

const Wrapper = styled.header(
  ({ showMenu, scrollPos }) => `
  position: fixed;
  z-index: 1000;
  width: 100vw;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  @media only screen and (min-width: ${breakpoints.md}px) {
    opacity: ${scrollPos < 450 || showMenu ? '1' : '0'};
    pointer-events: ${scrollPos > 450 && !showMenu ? 'none' : 'default'};
    height: ${scrollPos < 450 || showMenu ? '75px' : '0px'};
  }
`
)

const InnerWrapper = styled.div(
  ({ headerBg }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${headerBg ? DEFAULT_COLOR : 'transparent'};
  box-shadow: ${headerBg > 450 ? '1px 3px 16px -1px #00000073' : ''};
  justify-content: space-between;
  padding: 1rem;
  height: 75px;
`
)

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10000;
`

const Header = ({ siteTitle, headerBg }) => {
  const { direction, scrollPos } = useScrollDirection()
  return (
    <Wrapper
      showMenu={direction.isUp || !direction.isDown}
      scrollPos={scrollPos}
    >
      <InnerWrapper headerBg={scrollPos > 450 || headerBg}>
        <MenuWrapper>
          <HeaderMenu />
        </MenuWrapper>
        <Toolbar>
          <Login />
          <LanguageSwitcher />
        </Toolbar>
      </InnerWrapper>
    </Wrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
