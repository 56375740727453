import React, { useContext } from 'react'
import { languageContext } from '../../context/language-provider/index'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'

const Wrapper = styled.div`
  height: fit-content;
  margin-left: 0.5rem;
`
const FlagOption = styled.div`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  ${Wrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`

const Flag = styled.img(
  props => `
  &:hover {
    ${props.hover ? 'cursor: pointer; width: 34.2px; height: 22px;' : ''}
  }
  width: 32px;
  height: 20px;
  transition: all 0.3s ease-in-out;
`
)

const LanguageSwitcher = () => {
  const { selectedLanguage, toggle, languageOption } = useContext(
    languageContext
  )
  return (
    <Wrapper>
      <Flag src={selectedLanguage.svg} alt='Swedish flag' />
      <Icon fontSize="small">expand_more</Icon>
      <FlagOption>
        <Flag
          hover
          src={languageOption.svg}
          alt='English flag'
          onClick={() => toggle()}
        />
      </FlagOption>
    </Wrapper>
  )
}

export default LanguageSwitcher
