import React from 'react'
import { Link } from 'gatsby'
import useTranslation from '../../../hooks/use-translation'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  margin-left: 1.5rem;
`

const TestWrapper = styled.div`
  margin-bottom: 0.4rem;
  font-weight: bold;
  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background: darkgrey;
    transition: width 0.3s;
    transition-delay: 0.1s;
  }
`

const ChildWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
`

const ChildItem = styled.div`
  margin-left: 0.2rem;
  margin-top: 0.3rem;
  font-weight: bold;
`
const MobileMenuItem = ({ item }) => {
  const t = useTranslation()
  return (
    <Wrapper>
      <Link
        to={`/${item.url}`}
        style={{
          color: `#504e4e`,
          textDecoration: `none`,
          fontSize: '16px'
        }}
      >
        <TestWrapper><span>{t(item.label)}</span></TestWrapper>
      </Link>
      <ChildWrapper>
        {item.children &&
          item.children.map(child => (
              <Link
                to={`/${item.url}#${child.url}`}
                style={{
                  color: '#6f6868',
                  textDecoration: `none`,
                  fontSize: '14px'
                }}
              >
            <ChildItem key={child.url}>
                <span>{t(child.label)}</span>
            </ChildItem>
              </Link>
          ))}
      </ChildWrapper>
    </Wrapper>
  )
}

export default MobileMenuItem
