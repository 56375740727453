import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../helpers/media'
import { useStaticQuery, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import ContactButton from '../contact-button'
import { getNode, getNodes } from '../../helpers/graph-helper'
import useLanguage from '../../hooks/use-language'
import Img from 'gatsby-image'
import CustomRichText from '../custom-rich-text'

const Wrapper = styled.footer`
  background-color: #9f5ca7;
  position: relative;
  width: 100vw;
  z-index: 0;
  @media only screen and (max-width: ${breakpoints.md}px) {
    height: 600px;
  }
`

const InnerWrapper = styled.div`
  height: 215px;
  width: 100vw;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  @media only screen and (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
  }
`

const TextWrapper = styled.div`
  padding-left: 40px;
  color: #f7f4f4;
`

const Title = styled.h6`
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #f7f4f4;
  margin-bottom: 10px;
`

const Text = styled.div`
  text-align: left;
  letter-spacing: 0px;
  color: #c6c2c2;
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p {
    font-size: 18px;
    margin: 3px 0px;
  }
`
const LogoWrapper = styled.div`
  width: 375px;
  height: 155px;
  max-width: 90vw;
  @media only screen and (min-width: ${breakpoints.md}px) {
    width: 504px;
    height: 222px;
    max-width: 504px;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px;
  height: 100%;
  gap: 4%;
`
const Footer = () => {
  const language = useLanguage()
  const data = useStaticQuery(graphql`
    query FooterQuery {
      prismic {
        allFooters {
          edges {
            node {
              _meta {
                lang
              }
              dialog_content {
                title
              }
              footer_content {
                title
                text
              }
            }
          }
        }
        allCommons {
          edges {
            node {
              _meta {
                lang
              }
              avancez_image
              avancez_imageSharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 504) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  let footer = data && getNode(language.locale, data, 'allFooters')
  let common = data && getNode(language.locale, data, 'allCommons')
  return (
    <Wrapper>
      <InnerWrapper>
        <LogoWrapper>
          <Img
            imgStyle={{ objectFit: 'contain' }}
            fluid={common.avancez_imageSharp.childImageSharp.fluid}
          />
        </LogoWrapper>
        <RightWrapper>
          <ContactButton shadow={false} />
          <TextWrapper>
            {footer.footer_content.map((content, index) => (
              <React.Fragment key={index}>
                <Title>{RichText.asText(content.title)}</Title>
                <Text>
                  <CustomRichText richText={content.text} />
                </Text>
              </React.Fragment>
            ))}
          </TextWrapper>
        </RightWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export default Footer
