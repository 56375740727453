import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { DEFAULT_COLOR } from '../../../helpers/colors'
import { sectionContext } from '../../../context/section-provider'
import useScrollDirection from '../../../hooks/use-scroll-direction'
import useTranslation from '../../../hooks/use-translation'
import { IconButton, Icon, Tooltip } from '@material-ui/core'

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 150px;
  right: 0px;
  padding-right: 25px;
  padding-left: 25px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const MenuItem = styled.div(
  ({ show = true }) => `
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  color: white;
  width: ${show ? 'fit-content' : 0};
  margin: 10px 25px;
  overflow: ${!show && 'hidden'};
  background-color: ${DEFAULT_COLOR};
  box-shadow: 2px 2px 4px 1px #00000054;
  z-index: 10;
  border-radius: 7px;
  height: 45px;
  & p {
    font-size: 18px;
    font-weight: 800;
    padding: 10px;
    transition: all 0.1s ease-in-out;
    ::after {
      top: 8px;
      position: absolute;
      right: -12px;
      content: '';
      height: 30px;
      width: 30px;
      z-index: -10;
      transform: rotate(45deg);
      background-color: ${DEFAULT_COLOR};
    }
  }
  &:hover {
    cursor: pointer;
    & p {
      font-size: 19px;
    }
  }
`
)

const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Circle = styled.div(
  ({ offsetTop, index }) => `
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: ${offsetTop > 0.95 ? DEFAULT_COLOR : 'white'};
  border: 4px ${DEFAULT_COLOR} solid;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ::after {
    top: -65px;
    z-index: -10;
    position: absolute;
    content: '';
    width: 5px;
    background-color: ${offsetTop > 0.95 ? DEFAULT_COLOR : 'white'};
    box-shadow: 0px 0px 4px 1px #00000054;
    height: ${index !== 0 && '65px'};
  }
`
)

const TimelapseMenu = ({ children }) => {
  const [show, setShow] = useState(true)
  const t = useTranslation()
  const { scrollPos } = useScrollDirection()
  const { refs } = useContext(sectionContext)
  const click = menu => {
    const ref = refs[menu.url]
    if (ref) {
      window.scrollTo({
        top: ref.current.offsetTop - 75,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
  return (
    <Wrapper>
      <Column>
        <Tooltip title={t(show ? 'Hide menu' : 'Show menu')} placement="left">
          <IconButton
            style={{ backgroundColor: DEFAULT_COLOR, marginRight: '-15px' }}
            onClick={() => setShow(!show)}
            size="medium"
          >
            <Icon
              style={{
                color: 'white',
                transform: 'rotate(90deg)'
              }}
            >
              {show ? 'close' : 'chevron_right'}
            </Icon>
          </IconButton>
        </Tooltip>
        {show &&
          children.map((menu, i) => (
            <MenuItemWrapper key={i}>
              <MenuItem onClick={() => click(menu)}>
                <p>{t(menu.label)}</p>
              </MenuItem>
              <Circle
                index={i}
                offsetTop={
                  refs &&
                  refs[menu.url] &&
                  scrollPos / refs[menu.url].current.offsetTop
                }
              />
            </MenuItemWrapper>
          ))}
      </Column>
    </Wrapper>
  )
}

export default TimelapseMenu
