import React from "react"
import HeaderMenuItem from "../header-menu-item"
import styled from "styled-components"
import { Media, breakpoints } from "../../../helpers/media"
import MobileMenu from "../mobile-menu"
import logo from "../../../images/logo.white.png"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import ContactButton from "../../contact-button"
const items = [
  {
    label: "New Student",
    url: "sokande",
    children: [
      { label: "The Program", url: "om-programmet" },
      { label: "Studying at Chalmers", url: "studier" },
      { label: "The City for Students", url: "om-studentstaden" },
      { label: "Student life at Chalmers", url: "om-studentlivet" },
      { label: "Video introduction", url: "youtube" },
      { label: "Interviews", url: "intervjuer" },
    ],
  },
  {
    label: "Sektionen",
    url: "kommitteer",
    children: [
      {
        label: 'Styrelsen',
        url: 'styrelsen',
        absolute: true
      }, {
        label: 'Kommittéer',
        url: 'kommitteer',
        absolute: true
      }
    ],
    hideSections: true,
  },
  {
    label: "Golden-I",
    url: "golden-i",
  },
  {
    label: "Companies",
    url: "foretag",
  },
  {
    label: "Alumni plural",
    url: "alumner",
    hideSections: true,
  },
  {
    label: "Students",
    url: "app/studerande",
  },
]

const Wrapper = styled.div`
  display: none;
  @media only screen and (min-width: ${breakpoints.md}px) {
    display: flex;
    flex-direction: column;
  }
`

const TopMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Logo = styled.img`
  object-fit: cover;
  width: 75px;
  margin-top: 10px;
`
const HeaderMenu = () => {
  const { pathname } = useLocation()
  return (
    <>
      <Media lessThan="lg">
        <MobileMenu items={items} />
      </Media>
      <Media greaterThan="md">
        <Wrapper>
          <TopMenuWrapper>
            <Link
              to={`/`}
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              <Logo src={logo} alt="home" />
            </Link>
            {items.map((item, i) => (
              <HeaderMenuItem
                key={i}
                item={item}
                selected={pathname.includes(item.url)}
              />
            ))}
            <ContactButton header />
          </TopMenuWrapper>
        </Wrapper>
      </Media>
    </>
  )
}

export default HeaderMenu
