import React, { useContext } from 'react'
import { IconButton, Icon } from '@material-ui/core'
import styled from 'styled-components'
import useTranslation from '../../hooks/use-translation'
import { contactContext } from '../../context/contact-provider'
import { breakpoints } from '../../helpers/media'

const Wrapper = styled.div(
  ({ header }) => `
  margin-top: ${header ? '-4px' : '-3px'};
  margin-left: 10px;
  &:hover {
    cursor: pointer;
  }
`)
const Row = styled.div(
  ({ header }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: ${header ? 'inherit' : 'bold'};
  font-size: 22px;
  position: relative;
  ::after {
    left: 0px;
    bottom: -2px;
    position: absolute;
    content: '';
    display: block;
    width: 0px;
    height: 2px;
    background: #fff;
    transition: width 0.3s;
    transition-delay: 0.1s;
  }

  &:hover::after {
    width: 100%;
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    color: #f7f4f4;
  }

`
)

const ContactButton = ({ header = false }) => {
  const t = useTranslation()
  const { openDialog } = useContext(contactContext)
  return (
    <Wrapper onClick={openDialog} header={header}>
      <Row header={header}>
        {t`Contact`}
      </Row>
    </Wrapper>
  )
}

export default ContactButton
