/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { useStaticQuery, graphql, Script } from 'gatsby'

import Header from '../header'
import './layout.css'
import Footer from '../footer'

const Layout = ({ children, headerBg }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={{ position: 'relative', display: 'block', maxWidth: '100vw' }}>
      <Header headerBg={headerBg} siteTitle={data.site.siteMetadata.title} />
      <div style={{ position: 'relative', zIndex: 1, backgroundColor: 'white' }}>
        <iframe id="chatterFlowChat"
          src="https://chat-connect-technologies.vercel.app/embedded-chat/5eb10d0a-3d43-42ce-8bec-6b534239f553" loading="lazy"
          width="0.1px" height="0.1px" style={{ position: 'fixed', bottom: 0, right: 0 }}></iframe>
        <main>{children}
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
