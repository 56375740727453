import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@material-ui/core'
import MobileMenuItem from '../mobile-menu-item'
import ContactButton from '../../contact-button'

const MobileMenuWrapper = styled.div(
  props => `
  z-index: 999;
  background-color: ${props.visible ? 'lightgrey' : 'white'};
  opacity: ${props.visible ? '1' : '0'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${props.visible ? '100vh' : '0px'};
  transition: all 0.3s ease-in-out;
  overflow: scroll;
`
)

const MobileHeaderWrapper = styled.div`
  height: 100px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MobileMenuHead = styled.div`
  height: 100px;
  border-bottom: 1px solid darkgrey;
`

const MobileMenuItemsWrapper = styled.div`
  padding: 0.2rem;
`

const MobileMenu = ({ items }) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <>
      <MobileHeaderWrapper onClick={() => setCollapsed(!collapsed)}>
        <Icon
          className='hover-effect'
          fontSize='large'
          style={{
            color: 'white',
            zIndex: '10000',
            transform: `scaleX(${collapsed ? 2 : 1})`,
            position: 'absolute',
            opacity: collapsed ? '0' : '1',
            transition: 'all 0.5s ease-in-out'
          }}
        >
          menu
        </Icon>
        <Icon
          className='hover-effect'
          fontSize='large'
          style={{
            zIndex: '10000',
            transform: `rotate(${collapsed ? '0deg' : '90deg'})`,
            position: 'absolute',
            opacity: collapsed ? 1 : 0,
            transition: 'all 0.5s ease-in-out'
          }}
        >
          close
        </Icon>
      </MobileHeaderWrapper>
      <MobileMenuWrapper visible={collapsed}>
        <MobileMenuHead />
        <MobileMenuItemsWrapper>
          <MobileMenuItem item={{ label: 'Home', url: '' }} />
          {items.map((item, i) => (
            <MobileMenuItem key={i} item={item} />
          ))}
          <ContactButton header />
        </MobileMenuItemsWrapper>
      </MobileMenuWrapper>
    </>
  )
}

export default MobileMenu
