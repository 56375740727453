import React, { useContext, useState, useRef } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import { userContext } from '../../context/user-provider'
import { DEFAULT_COLOR, DEFAULT_COLOR_RGBA } from '../../helpers/colors'
import useTranslation from '../../hooks/use-translation'
import styled from 'styled-components'
import useOutsideClick from '../../hooks/ous-outside-click'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const UserWrapper = styled.div``

const UserSymbol = styled.div(
  props => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 65px;
  background-color: ${props.active ? DEFAULT_COLOR : DEFAULT_COLOR_RGBA(0.7)};
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${DEFAULT_COLOR};
    cursor: pointer;
  }
`
)

const UserImage = styled.img`
  position: absolute;
  height: 53px;
  width: 53px;
  margin-top: 3px;
  margin-left: 1px;
`

const UserText = styled.p`
  color: white;
  font-weight: 300;
  margin-top: -5px;
  font-size: 17px;
`

const UserMenu = styled.div`
  position: absolute;
  padding-top: 10px;
`

const Login = () => {
  const [showUserMenu, setShowUserMenu] = useState(false)
  const userRef = useRef(null)
  const { user, loading, initializeLogin, logout, loginUrl } = useContext(
    userContext
  )

  const t = useTranslation()

  useOutsideClick(userRef, () => {
    setShowUserMenu(false)
  })

  return (
    <Wrapper>
      {user ? (
        <UserWrapper ref={userRef}>
          <UserSymbol
            active={showUserMenu}
            onClick={() => setShowUserMenu(!showUserMenu)}
          >
            <UserImage src='https://images.prismic.io/ichalmers/2669eb8e-0e0a-4bf5-b049-bf13cf2fb183_krans.svg?auto=compress,format' />
            <UserText>{user.initials}</UserText>
          </UserSymbol>
          {showUserMenu && (
            <UserMenu>
              <Button
                target='_blank'
                onClick={() => {
                  const w = window.open('', '_blank')
                  w.location =
                    'https://sso.i-chalmers.se/Shibboleth.sso/Logout?return=https://sso.i-chalmers.se/api/logout.callback'
                  w.location =
                    'https://sso.i-chalmers.se/Shibboleth.sso/Logout?return=https://sso.i-chalmers.se/api/logout.callback'
                  w.location =
                    'https://sso.i-chalmers.se/Shibboleth.sso/Logout?return=https://sso.i-chalmers.se/api/logout.callback'
                  w.location =
                    'https://sso.i-chalmers.se/Shibboleth.sso/Logout?return=https://sso.i-chalmers.se/api/logout.callback'
                  w.location =
                    'https://sso.i-chalmers.se/Shibboleth.sso/Logout?return=https://sso.i-chalmers.se/api/logout.callback'
                  w.location =
                    'https://sso.i-chalmers.se/Shibboleth.sso/Logout?return=https://sso.i-chalmers.se/api/logout.callback'

                  logout()
                }}
              >
                {t`Logout`}
              </Button>
            </UserMenu>
          )}
        </UserWrapper>
      ) : (
        <>
          {!loading && (
            <Button
              onClick={async () => {
                const w = window.open('', '_blank')
                setShowUserMenu(false)
                const link = await initializeLogin()
                if (link) {
                  w.location = link
                }
              }}
              style={{ backgroundColor: DEFAULT_COLOR }}
            >
              <p style={{ color: 'white' }}>{t`Login`}</p>
            </Button>
          )}
          {loading && <CircularProgress />}
        </>
      )}
      {loginUrl && loading && (
        <Dialog open='true'>
          <DialogTitle>Inloggning pågår</DialogTitle>
          <DialogContent style={{ textAlign: 'center' }}>
            <p>Har du blockerat popups?</p>
            <p> Klicka på knappen för att starta inloggning på nytt</p>
            <Button
              variant='contained'
              style={{ backgroundColor: DEFAULT_COLOR }}
              href={loginUrl}
              target='_blank'
            >
              <p style={{ color: 'white' }}>{t`Login`}</p>
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </Wrapper>
  )
}

export default Login
