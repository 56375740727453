export const getNode = (locale, data, field) => {
  return (
    data.prismic[field].edges.filter(x => x.node._meta.lang === locale)[0]
      .node || data.prismic[field].edges[0].node
  )
}

export const getNodes = (locale, data, field) => {
  return (
    (data.prismic[field].edges.filter(x => x.node._meta.lang === locale) || data.prismic[field].edges).map(edge => edge.node)
  )
}

export const getNodesTest = (locale, edges) => {
  return (
    (edges.filter(x => x.node._meta.lang === locale) || edges).map(edge => edge.node)
  )
}

