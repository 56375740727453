import React from 'react'
import { Link } from 'gatsby'
import useTranslation from '../../../hooks/use-translation'
import styled from 'styled-components'
import TimelapseMenu from '../timelapse-menu'

const Wrapper = styled.div`
  margin: 1rem;
  ::after {
    content: '';
    display: block;
    width: 0px;
    height: 2px;
    background: lightgrey;
    transition: width 0.3s;
    transition-delay: 0.1s;
  }
  
  &:hover::after {
    width: 100%;
  }
`

const MenuDropdownWrapper = styled.div`
  position: absolute;
  margin-top: 0rem;
  max-height: 0px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  transition: all 0.5s ease-in;
  transition-delay: 0s;
  opacity: 0;
  ${Wrapper}:hover && {
    opacity: 1;
    max-height: 400px;
    margin-top: 0.5rem;
  }
`
const MenuDropdown = styled.div`
  display: flex;
  flex-direction: column;
`

const ExpandMoreWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
`
const ExpandMoreLeft = styled.div`
  background-color: white;
  width: 12px;
  height: 1px;
  margin-left: 3px;
  transition: all 0.5s ease-in-out;
  transform: rotate(45deg);
  ${Wrapper}:hover && {
    transform: rotate(0deg);
  }
`

const ExpandMoreRight = styled.div`
  background-color: white;
  width: 12px;
  height: 1px;
  margin-left: -3.5px;
  transition: all 0.5s ease-in-out;
  transform: rotate(-45deg);
  ${Wrapper}:hover && {
    transform: rotate(0);
  }
`

const DropdownItem = styled.div`
  min-width: 100px;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    background: lightgrey;
  }
`

const DropdownLabel = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.2rem;
  padding-right: 0.5rem;
`

const Title = styled.p`
  transition: color 0.3s cubic-bezier(0.45, 0, 0.55, 1) 0s;
  &:hover {
    color: lightgrey;
  }
`
const HeaderMenuItem = ({ item, selected }) => {
  const t = useTranslation()
  return (
    <>
      <Wrapper>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Link
            to={`/${item.url}`}
            style={{
              color: `white`,
              textDecoration: `none`
            }}
          >
            <Title
              style={{ fontSize: '22px' }}
            >
              {t(item.label)}
            </Title>
          </Link>
          {item.children && (
            <ExpandMoreWrapper>
              <ExpandMoreLeft />
              <ExpandMoreRight />
            </ExpandMoreWrapper>
          )}
        </div>
        {item.children && (
          <MenuDropdownWrapper>
            <MenuDropdown>
              {item.children.map((child, i) => (
                <Link
                  key={i}
                  to={child.absolute ? `/${child.url}` : `/${item.url}#${child.url}`}
                  style={{
                    color: 'black',
                    textDecoration: `none`,
                    fontSize: '14px'
                  }}
                >
                  <DropdownItem>
                    <DropdownLabel>{t(child.label)}</DropdownLabel>
                  </DropdownItem>
                </Link>
              ))}
            </MenuDropdown>
          </MenuDropdownWrapper>
        )}
      </Wrapper>
      {selected && item.children && !item.hideSections && (
        <TimelapseMenu children={item.children} />
      )}
    </>
  )
}

export default HeaderMenuItem
